
import { mdiHelpCircleOutline } from "@mdi/js"

export default {
    name: "HelpTooltip",
    props: {
        iconColor: {
            type: String,
            default: "grey-4--text",
        },
        icon: {
            type: String,
            default: mdiHelpCircleOutline,
        },
        size: {
            type: [Number, String],
            default: undefined,
        },
    },
}
